import httpHelper from "@/helpers/httpHelper";

const buyGoldService = {
    async getRules(data) {
        return await httpHelper.get("/WebApi/BuyGold/GetRules", data);
    },
    async buy(data, reCaptchaToken) {
        return await httpHelper.post("/WebApi/BuyGold/Buy", data, {
            headers: {
                reCaptchaToken
            }
        });
    },
};

export default buyGoldService;