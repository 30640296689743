<template>
  <div class="page page-account">
    <b-col class="py-3">
      <GameHeader :page-name="title"/>
      <RowLine />
      <div class="game-feature">
        <h1 class="feature-title">{{title}}</h1>
        <ValidationObserver ref="form" tag="form" class="form app-form" @submit.prevent="onSubmit()">
          <b-form-group>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-select v-model="formData.idServer" :options="servers" value-field="id" text-field="name" :disabled="loading || formData.idGame < 1"/>
              <div class="msg-validate-error">{{ errors[0] }}</div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-select v-model="formData.idRole" :options="roles" value-field="id" text-field="name" :disabled="loading || formData.idServer < 1"/>
              <div class="msg-validate-error">{{ errors[0] }}</div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-select v-model="formData.money" :options="moneys"  value-field="id" text-field="name" :disabled="loading || formData.idRole < 1"/>
              <div class="msg-validate-error">{{ errors[0] }}</div>
            </ValidationProvider>
          </b-form-group>
          <b-form-group>
            <b-button type="submit" variant="primary" block size="lg" class="font-weight-bold" :disabled="loading">
              {{  game ? $t("Buy a {goldName} package", {goldName: game.goldName}) : $t("Buy a gold package") }}
            </b-button>
          </b-form-group>
          <b-form-group class="pt-2">
            <div class="font-weight-bold">
              1. {{ game && game.goldName}} is the currency used a lot in the Pokemon world, in terms of rarity and convenience, second only to diamonds.
            </div>
            <div>
              2. This shop helps you to own gold quickly without spending much effort searching in the game.
            </div>
            <div class="font-weight-bold text-danger">
              3. Buying items at this Shop will not increase benefits or VIP points in the game, please pay attention to avoid miscalculation.
            </div>
            <div>
              4. {{ game && game.goldName}} will be sent to in-game mail after purchase!
            </div>
          </b-form-group>
        </ValidationObserver>
        <LoadingModal :show="loading"/>
      </div>
    </b-col>
  </div>
</template>

<script>
import GameHeader from "@/components/headers/GameHeader";
import serverGameService from "@/services/serverGameService";
import buyGoldService from "@/services/buyGoldService";
import roleService from "@/services/roleService";
import dialogHelper from "@/helpers/dialogHelper";

export default {
  name: "Index",
  components: {GameHeader},
  data() {
    return {
      loading: false,
      servers: [{
        id: 0,
        name: this.$t("Select a server")
      }],
      roles: [{
        id: 0,
        name: this.$t("Select a role")
      }],
      moneys: [{
        id: 0,
        name: this.$t("Select a gold value")
      }],
      game: null,
      title: null,
      formData: {
        idGame: 0,
        idServer: 0,
        idRole: 0,
        money: 0
      }
    }
  },
  created() {
    this.title = this.$t("Buy gold");
    this.$bridge.$on("setCurrentGame", this.onSetGame);
  },
  beforeDestroy() {
    this.$bridge.$off("setCurrentGame", this.onSetGame);
  },
  methods: {
    onSetGame(game) {
      if (!game)
      {
        this.$gotoHomePage();
        return;
      }

      this.game = game;
      this.title = this.$t("Buy {{goldName}}", {goldName:  game.goldName});
      this.formData.idGame = game.id;
      this.loadMoneys(game.id);
    },
    async loadMoneys(idGame) {
      this.loading = true;
      const response = await buyGoldService.getRules({
        idGame: idGame
      }) ;

      if (!response)
      {
        this.loading = false;
        await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        await this.$gotoHomePage();
        return;
      }

      if (response.error)
      {
        this.loading = false;
        await dialogHelper.alert(response.message);
        await this.$gotoHomePage();
        return;
      }

      this.moneys = [...this.moneys, ...response.data];
      await this.loadServers(idGame);
    },
    async loadServers(idGame) {
      let servers = [{
        id: 0,
        name: this.$t("Select a server")
      }];

      const response = await serverGameService.getAllByGame(idGame);
      this.loading = false;
      if (!response)
        await dialogHelper.alert("Connect to server failed. Please check your internet connection");
      else if (response.error)
        await dialogHelper.alert(response.message);
      else
        servers = [...servers, ...response.data];

      this.servers = servers;
      this.formData.idServer = servers.length > 1 ? servers[1].id : 0;
    },
    async onSubmit() {
      const valid = await this.$refs.form.validate();
      if (!valid)
        return;

      this.loading = true;
      await this.$recaptchaLoaded();
      const response = await buyGoldService.buy(this.formData, await this.$recaptcha('buyGold'))
      this.loading = false;

      if (!response)
      {
        await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        return;
      }

      if (response.error) {
        await dialogHelper.alert(response.message);
        return;
      }

      await dialogHelper.alert(response.message, "success");
      this.formData.idRole = 0;
    }
  },
  watch: {
    "formData.idServer": async function (newValue) {
      let roles = [{
        id: 0,
        name: this.$t("Select a role")
      }];

      if (newValue > 0) {
        this.loading = true;
        const response = await roleService.getRolesByServer(newValue);
        this.loading = false;
        if (!response)
          await dialogHelper.alert("Connect to server failed. Please check your internet connection");
        else if (response.error)
          await dialogHelper.alert(response.message);
        else
          roles = [...roles, ...response.data];
      }

      this.roles = roles;
      this.formData.idRole = roles.length > 1 ? roles[1].id :  0;
    },
  }
}
</script>

<style scoped>

</style>
